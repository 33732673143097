import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import facebookIcon from "../../images/logo-facebook.svg"
import { useDispatch, useSelector } from "react-redux";
import { saveFacebook } from "../../Actions/SocialAction";
import axios from "axios";


const ConnectFacebook = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const [dropValue, setDropValue] = useState("Select An Account")
    const [groups, setGroups] = useState([])
    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    })
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    })


    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = async () => {
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        await dispatch(saveFacebook(data));
        setDetails({ ...details, openPopup: false });
        setLoader(false);
    };
    const responseFacebook = async (response) => {

         try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );

            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }

        /** Fetch FB Groups */
        try {
            const res = await axios.get(
                " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
            );

            setGroups(res.data.data);
        } catch (err) {
            console.log(err);
        }

        // if (response.status !== "unknown") {
        //     setLoader(true);
        //     const data = {
        //         user_id: auth.user.id,
        //         title: response.name,
        //         token: response.accessToken,
        //         fb_id: response.userID,
        //         category: response.userID,
        //     };
        //     await dispatch(saveFacebook(data, props.fetchIntegration));
        //     setDetails({ ...details, openPopup: false });
        //     setLoader(false);
        // }
    }

    return (
        <>
            <div className="integr-single" id="facebook">

                <div className="connection-box-main">
                    <div className={`connection-img disable-img`}>
                        <img src={facebookIcon} width="56px" alt="facebook" />
                    </div>

                    <div className="connection-txt">
                        {
                            <div className="noConnect">
                                <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>Facebook</h6>
                                        <p className="m-0">Connect Facebook Pages & groups</p>
                                    </div>
                                </div>
                                <div className="facebook-connect-account">
                                    <FacebookLogin
                                        appId="780210132546000" //APP ID NOT CREATED YET
                                        fields="name,email"
                                        scope="ads_management,business_management,pages_manage_ads,pages_read_engagement,pages_show_list"
                                        callback={(e) => responseFacebook(e)}
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                                        )}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>



            <div className={`modal ${details.openPopup ? 'show-modal' : ''} `}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ maxWidth: "500px", margin: "auto" }}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Connect Facebook
                            </h5>
                            <button onClick={(e) => closeConnectModel()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" style={{ backgroundColor: "transparent" }}>
                                <select className="form-control" id="exampleFormControlSelect1" onChange={handleChange}
                                    value={dropValue}>
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Your Facebook Groups">
                                        {groups.map((group) => (
                                            <option
                                                key={group.id}
                                                value={
                                                    group.name +
                                                    "___CR___" +
                                                    details.accessToken +
                                                    "___CR___group___CR___" +
                                                    group.id
                                                }
                                            >
                                                {group.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                            </form>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <div className="row">
                                <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                    <div className="add-account-save-btn">
                                        <button type="submit" className="btn-change7" onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}>
                                            {loader ? <>Connecting <i className="fa fa-spinner fa-spin mr-2" /> </> : "Connect"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ConnectFacebook;