import React, { useEffect, useState } from 'react'
import { addMediaLayer, onFetchBusinessGraphic, updateBackground, updateMediaLayer } from '../../../../Actions/CampaignAction'
import { useDispatch, useSelector } from 'react-redux'
import HoverVideoPlayer from "react-hover-video-player";

const BusinessGraphic = ({ selectedSlideIndex, selectedLayerIndex, selectedSlide, type }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.campaign.data)
    const [businessDataImages, setBusinessDataImages] = useState([])
    const [businessDataVideo, setBusinessDataVideo] = useState([])
    const [loader, setLoader] = useState(false)



    const selectImage = (image) => {
        let data = {
            url: image,
            thumbnail: image
        }
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video") {
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "image"))
                }
                else {
                    dispatch(addMediaLayer(data, selectedSlideIndex, "image"))
                }
            } else {
                dispatch(addMediaLayer(data, selectedSlideIndex, "image"))
            }
        } else {
            dispatch(updateBackground(data, selectedSlideIndex, "image"))
        }
    }

    const selectVideo = async (video) => {
        let data = {
            url: video,
            thumbnail: video
        }
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video") {
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "video"))
                }
                else {
                    dispatch(addMediaLayer(data, selectedSlideIndex, "video"))
                }

            } else {
                dispatch(addMediaLayer(data, selectedSlideIndex, "video"))

            }
        } else {
            return new Promise((resolve, reject) => {
                let au = document.createElement('video');
                au.src = video
                if (video.bgDuration !== undefined) { }
                else {
                    video = {
                        ...video,
                        bgDuration: 0
                    }
                }
                au.addEventListener('loadedmetadata', function () {
                    video.bgDuration = Math.round(au.duration)
                    dispatch(updateBackground(data, selectedSlideIndex, "video"))
                    resolve(au.duration)
                }, false);
            })
        }
    }

    const fetchBusiness = () => {
        setLoader(true)
        let data = {
            cId: campaign.cId
        }
        dispatch(onFetchBusinessGraphic(data, setBusinessDataImages, setBusinessDataVideo, setLoader))
    }

    useEffect(() => {
        fetchBusiness()
    }, [])
    return (
        <div className="tab-outer">
            <div className="audio-library" style={{ maxHeight: "655px" }}>
                {businessDataImages.length > 0 ?
                    <h6 className='mb-2'>Images</h6> : ""}
                <ul>
                    {
                        businessDataImages.length > 0 ?
                            businessDataImages.map((curElem, index) => {
                                return (
                                    <li className="cursor-pointer"
                                        key={index}
                                        onClick={() => selectImage(curElem)}
                                    >
                                        <div className={`audio-library-single `} >
                                            <div className="audio-details" >
                                                <img
                                                    className="mediaImg"
                                                    src={curElem} alt=""
                                                />
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                            : ''
                    }
                </ul>
                {businessDataVideo.length > 0 ?
                    <h6 className='my-2'>Videos</h6> : ""}
                <ul>
                    {
                        businessDataVideo.length > 0 ?
                            businessDataVideo.map((curElem, index) => {
                                return (
                                    <li
                                        className="cursor-pointer"
                                        key={index}
                                        onClick={() => selectVideo(curElem)}
                                    >
                                        <div className={`audio-library-single `} style={{ textAlign: "center" }}>
                                            <HoverVideoPlayer
                                                className="hover-video-player"
                                                style={{ height: '100%', overflow: 'hidden' }}
                                                videoSrc={curElem}
                                                loadingOverlay={
                                                    <div><i className="fa fa-spinner fa-spin hover-loader-center" style={{ color: "#e0712c" }} /></div>
                                                }
                                            />
                                        </div>
                                    </li>
                                )
                            })
                            : ''
                    }
                </ul>
                {
                    businessDataVideo.length > 0 || businessDataImages.length > 0 ?
                        ""
                        :
                        <div className='text-center'>
                            {
                                loader ?
                                    <i className="fa fa-spinner fa-spin" style={{ color: "#e0712c", fontSize: 35 }} />
                                    :
                                    <span >No Data Found!</span>
                            }
                        </div>
                }
            </div>
        </div >
    )
}

export default BusinessGraphic