import React, { useState } from 'react'
import TitleBar from "../../TitleBar";
import Navbar from "../../Navbar";
import { useLocation, useNavigate } from 'react-router-dom';
import { onUpdateEmotion } from '../../../Actions/CampaignAction';
import { useDispatch } from 'react-redux';
import { FaLessThan } from 'react-icons/fa'
import { BsChevronDown } from 'react-icons/bs'
import queryString from "query-string"
const Emotion = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const id = queryString.parse(location.search).cId
    const campaignName = queryString.parse(location.search).name
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        cId: id,
        keyword: "",
        emotion: "",
        dimension: "short"
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value });
    }

    const handleSelect = (value) => {
        setState({ ...state, dimension: value })
        setShow(false)
    }

    const handleShow = (e) => {
        setShow(!show)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            cId: id,
            keyword: state.keyword,
            emotion: state.emotion,
            dimension: state.dimension
        }
        dispatch(onUpdateEmotion(data, navigate, setLoader))
    }
    const handleBack = () => {
        navigate(`/create-video`)
    }


    return (
        <>
            <TitleBar title="Emotion" />
            <Navbar />
            <section className="siteWrap">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="projectTitle-wrap">
                            <div className="container">
                                <div className="projectTitle">
                                    <div className="projectTitle-left d-flex align-items-center">
                                        <span onClick={handleBack}><FaLessThan style={{ fontSize: "30px", marginRight: "11px" }} /></span>
                                        <span style={{ color: "#fff", fontSize: '24px', fontWeight: "bold" }}>{campaignName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container">
                    <form onSubmit={handleSubmit}>
                        <div className="row justify-content-center ">
                            <div className="col-md-12 mt-3">
                                <span style={{ color: "#fff", position: "relative", top: "17px", left: "8px" }}>Anything you want to highlight ? if not just leave this blank.</span>
                            </div>
                            <div className="col-lg-7">
                                <div className=" Ai_instruction_form_wrap">
                                    <div className="form-group  Ai_instruction_form" style={{ position: "relative" }}>
                                        <span style={{ position: "absolute", top: "0", width: "92%", height: "28px", background: "#000", paddingTop: "6px" }}>Video Prompt</span>
                                        <div className="d-flex align-items-start">
                                            <textarea
                                                type="text"
                                                name="keyword"
                                                className="form-control Ai_instruction_input top-aligned-input"
                                                placeholder="Message, slogan, product name, job title or anything else"
                                                value={state.keyword}
                                                onChange={handleChange}
                                                required
                                                style={{ paddingTop: "24px" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className=" Ai_instruction_form_wrap">
                                    <div className="form-group  Ai_instruction_form">
                                        <span>Vibe</span>
                                        <select
                                            class="form-select form-control Ai_instruction_select"
                                            aria-label="Default select example"
                                            name="emotion"
                                            value={state.emotion}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option selected value="">Select Vibe</option>
                                            <option selected value="laidback">Laid-Back</option>
                                            <option selected value="fun">Fun</option>
                                            <option selected value="inspiration">Inspiration</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className=" Ai_instruction_form_wrap">
                                    <div className="form-group  Ai_instruction_form" style={{ overflow: "unset", border: "1px solid #e0712c" }} onClick={handleShow} >
                                        <span>Format</span>

                                        <div className="selectformat d-flex justify-content-between align-content-center"  >
                                            <span style={{ marginLeft: "10px", fontSize: "14px" }}>{state.dimension.split(":")[0]}</span>
                                            <span>
                                                <BsChevronDown style={{ fontSize: "21px" }} />
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        show ?
                                            <>
                                                <div className="format_wrap">
                                                    <ul>
                                                        <li
                                                            className={state.dimension === "Short Video" ? "active" : ""}
                                                            onClick={() => handleSelect("short")}
                                                            style={{ width: "150px", cursor: "pointer" }}
                                                        >
                                                            <span>Short video</span>
                                                        </li>
                                                        <li
                                                            className={state.dimension === "Medium Video" ? "active" : ""}
                                                            onClick={() => handleSelect("medium")}
                                                            style={{ width: "150px", cursor: "pointer" }}
                                                        >
                                                            <span>Medium video</span>
                                                        </li>
                                                        <li
                                                            className={state.dimension === "Long Video" ? "active" : ""}
                                                            onClick={() => handleSelect("long")}
                                                            style={{ width: "150px", cursor: "pointer" }}
                                                        >
                                                            <span>Long video</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                            : ""
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="row justify-content-end">
                            <div className="col-xxl-3 col-lg-3 ">
                                <button type="submit" className="fbtn btn-block demoLink" >{loader ? <> Generating Video<i className="fa fa-spinner fa-spin mx-1" /></> : "Generate Video"}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section >

        </>
    )
}

export default Emotion
